/** One for the road **/

import React from "react";
import { positionText, resetAnimationDelay, showText, hideText } from "../../helpers"
import FullscreenVideo from "../../components/media/fullscreen-video"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { gsap, Back } from "gsap";

let globalState, data;

class OneForTheRoadPage1 extends React.Component {

    constructor(props) {
        super(props);

        //update global state and assets data
        globalState = this.props.location.state;
        data = globalState.assets[1];

        //refs
        this.fullscreenBg = null;
    }

    state = {
        textVisible: false,
        aboutOverlayVisible: false
    }




    componentDidMount = () => {

        setTimeout(() => {
            this.bgVideoRef.playVideo();
        }, globalState.transitionDuration);


        //position text
        positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
        positionText(this.refs.text2Ref, "left", "top", globalState.textPosition.leftTop);
        positionText(this.refs.text3Ref, "right", "top", globalState.textPosition.rightTop);


        //show text
        gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });
        gsap.to(this.refs.text2Ref, 0, { autoAlpha: 1.0 });
        gsap.to(this.refs.text3Ref, 0, { autoAlpha: 1.0 });

        //show prev next    
        this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
    }

    

    componentWillUnmount() {
        this.bgVideoRef.pauseVideo();

        //Kill any tweens
        gsap.killTweensOf(this.refs.text1Ref);
        //gsap.killTweensOf(this.refs.text2Ref);
    }

    bgVideoEnded = () => {
        this.bgVideoRef.muteVideo();
    }


    isTransitioning = () => {
        this.bgVideoRef.pauseVideo();
        //pause animations
    }

    goToGoggles = (event) => {
        //go to goggles website
        var win = window.open('http://www.thegoggles.org/', '_blank');
        win.focus();
    }

    goToPPRevisited = (event) => {
        //go to PP Revisted website
        var win = window.open('https://pinepointrevisited.homestead.com/', '_blank');
        win.focus();
    }

    


    toggleAboutOverlay = (event) => {

        let isOpen = this.state.aboutOverlayVisible;

        

        //if closed
        if (!isOpen) {
            //toggle about overlay visibility
            this.setState({ aboutOverlayVisible: !this.state.aboutOverlayVisible });
            //open
            setTimeout(() => {
                gsap.to(this.refs.michaelRef, 0.5, { y: 20, ease: Back.easeOut });
                gsap.to(this.refs.paulRef, 0.5, { y: 20, ease: Back.easeOut, delay: 0.3 });
                gsap.to(this.refs.nfbRef, 0.5, { y: 20, ease: Back.easeOut, delay: 0.6 });

            }, 300);
        } else {
            //close
            gsap.to(this.refs.michaelRef, 0.4, { y: 200, ease: Back.easeOut });
            gsap.to(this.refs.paulRef, 0.4, { y: 200, ease: Back.easeOut, delay: 0.1 });
            gsap.to(this.refs.nfbRef, 0.4, { y: 100, ease: Back.easeOut, delay: 0.2 });
            setTimeout(()=>{
                //toggle about overlay visibility
                this.setState({ aboutOverlayVisible: !this.state.aboutOverlayVisible });
            }, 600);
        }

    }

    toggleTextDisplay = (event) => {

        //toggle text visibility
        this.setState({ textVisible: !this.state.textVisible });

        //reset the animation delay
        resetAnimationDelay();

        if (this.state.textVisible) {
            //show text
            showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);
            showText(this.refs.text2Ref, "left", "top", globalState.textPosition.leftTop);
            showText(this.refs.text3Ref, "right", "top", globalState.textPosition.leftTop);

        } else {
            //hide text
            hideText(this.refs.text1Ref, "left");
            hideText(this.refs.text2Ref, "left");
            hideText(this.refs.text3Ref, "right");
        }
    }


    render() {

        return (

            <>
                <PrevNext globalState={globalState} ref="prevNextRef"
                    nextLocation="" prevLocation="/one-for-the-road"
                    isTransitioning={this.isTransitioning} />

                {data && (
                <div className="fullpage-wrapper">
                    <SEO title="Pine Point - One For the Road" />

                    <div className="text-wrapper">
                        <div className="text text--left-top" ref="text1Ref">
                            <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} alt="THEY GET TOGETHER ONCE A YEAR AT THE PINE POINT BASH." onClick={this.toggleTextDisplay} />
                        </div>
                        <div className="text text--left-top" ref="text2Ref">
                            <input type="image" src={globalState.cdnUrl + data.text2} style={{ marginLeft: "0", marginTop: "90px" }} alt="Over a weekend, they set off fireworks, talk about a place that once was, find out what became of who, when. Richard still hopes to go, one last time. I can’t help but wonder if Pine Pointers are in some way happier people – if there is a kind of relief in having their hometown gone and their memories cast in amber. I couldn’t find an answer to that question. I haven’t had their past. They haven’t had my future. Do they wonder about the day the Bash will come to an end? This year was cancelled, due to ‘conflicting functions.’ Someone still mows the grass in Pine Point’s cemetery." onClick={this.toggleTextDisplay} />
                        </div>
                        <div className="text text--right-top" ref="text3Ref">
                            <input type="image" src={globalState.cdnUrl + data.text3} style={{ marginRight: "0", marginTop: "0" }} alt="2008 BASH, GREEN COURT, ALBERTA, 1026 KM SOUTH OF PINE POINT" onClick={this.toggleTextDisplay} />
                        </div>

                        <button className="about-btn" style={{ right: "60px", bottom: "60px" }} onClick={this.toggleAboutOverlay}></button>

                    </div>

                    {data.bgVideo ?
                        <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
                            poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} muted={false} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
                    }

                    {this.state.aboutOverlayVisible ?

                        <div className="about-overlay">
                            <div className="about-wrapper">
                                <img src={globalState.cdnUrl + data.aboutBg} alt="" className="aboutBg" />
                                <img src={globalState.cdnUrl + data.aboutText1} alt="This was supposed to be a book. We do a lot of our work in print: we were the creative team at Adbusters Magazine, and more recently, co-authored a book entitled I Live Here, about four forgotten corners of the global political landscape. We were developing a concept for a book, about the death of photo albums as a way to house memory. One night, reminiscing about a place he once visited, Michael stumbled across Richard Coltier’s Pine Point Revisited website. We were hooked. It seemed the site acted as a kind of public album, a touchstone for the people who lived there, but also, for those of us who didn’t; it was unpretentious, honest, and represented a sentiment and perspective that we felt needed sharing. It could have been a book, but it probably makes more sense that it became this." className="aboutText1" />{/* eslint-disable-line */}
                                <img src={globalState.cdnUrl + data.aboutText2} alt="Produced by the National Film Board of Canada, Pine Point was created by Paul Shoebridge and Michael Simons. They live in Vancouver, where they produce story-based media projects through their company The Goggles." className="aboutText2" />
                                <div className="michael-wrapper">
                                    <img src={globalState.cdnUrl + data.michael} alt="Michael" ref="michaelRef" className="michael" />
                                </div>
                                <div className="paul-wrapper">
                                    <img src={globalState.cdnUrl + data.paul} alt="Paul" ref="paulRef" className="paul" />
                                </div>
                                <div className="nfb-wrapper">
                                    <img src={globalState.cdnUrl + data.nfb} alt="NFB" className="nfb" ref="nfbRef" />
                                </div>
                                <button className="aboutClose" onClick={this.toggleAboutOverlay}></button>
                                <button className="revisitedBtn" onClick={this.goToPPRevisited}></button>
                                <button className="goggleBtn" onClick={this.goToGoggles}></button>
                            </div>
                        </div> : ""
                    }

                </div>
                )}
            </>
        );
    }


};

export default OneForTheRoadPage1
